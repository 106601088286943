@import '../../scss/colours';

.navigation {
    background-color: var(--primary);
    box-shadow: 0px 0 4px 1px #0000006e;

    padding: 1.5em;

    display: flex;
    justify-content: space-between;
    align-items: center;

    @media print {
        display: none;
    }

    .left {
        display: flex;
        align-items: center;

        > a > img {
            height: 50px;
        }

        small {
            margin-left: 50px;
            color: var(--primary);
            font-size: 24px;
        }
    }

    .bookmarks {
        flex: 1;
        display: flex;
        gap: 20px;
        align-self: end;
        flex-wrap: wrap;

        > a {
            text-decoration: none;
            background-color: var(--cobalt);
            border: none;
            border-radius: 5px;
            color: white;
            padding: 4px;
            font-size: 13px;
            font-weight: bold;
            line-height: 20px;
        }
    }

    .right {
        justify-self: flex-end;
        align-self: center;

        > a {
            text-decoration: none;

            color: var(--primary);

            transition: all .25s;

            &:hover {
                color: var(--secondary);
            }
        }

        > .user {
            display: flex;
            position: relative;
            align-items: center;
            cursor: pointer;
            ::selection {
                background: transparent;
            }

            > * {
                margin-right: 10px;
            }

            > :last-child {
                margin-right: 0;
            }

            > img {
                height: 40px;

                border-radius: 50%;
            }

            svg {
                margin-left: 10px;
                transition: all 0.5s;
            }

            > div {
                position: relative;

                > p, div {
                    display: inline;
                }

                .account-menu {
                    position: absolute;
                    top: 100%;
                    background-color: var(--white);
                    width: 100%;
                    border-radius: 4px;
                    text-align: center;
                    border: 1px solid var(--light-gray);
                    transition: all 0.5s;
                    left: 0;

                    a {
                        display: block;
                        border-bottom: 1px solid var(--light-gray);
                        font-size: 14px;
                        padding: 5px;
                        text-decoration: none;
                        color: var(--gray);
                        &:last-child {
                            border-bottom: none;
                        }
                        &:hover {
                            background-color: var(--light-gray);
                        }
                    }
                }
            }

            &.menu-closed {
                .account-menu {
                    opacity: 0;
                    pointer-events: none;
                }
            }

            &.menu-open {
                .account-menu {
                    opacity: 1;
                    pointer-events: all;
                }

                svg {
                    transform: rotate(-90deg);
                }
            }
        }
    }
}
